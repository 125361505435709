<template>
  
  <v-btn icon 
         :class="btnClass" 
         @click.stop="toggleFav"
         :loading="loading">
    <v-icon :color="iconColor">{{ icon }}</v-icon>
  </v-btn>

</template>

<script>

  import Api from '../api'

  export default {
    name: 'FavouriteButton',

    components: {
      
    },

    props: {
      btnClass: {
        type: String
      },
      modelType: {
        type: String,
        required: true
      },
      modelId: {
        type: Number,
        required: true
      },
      status: {
        type: Boolean,
        default: ()=>false
      }
    },

    data: () => ({
      loading: false
    }),

    mounted(){},

    computed: {

      icon()
      {
        return 'mdi-star' + (this.status ? '':'-outline')
      },

      iconColor()
      {
        return this.status ? 'yellow darken-3' : ''
      }

    },

    methods: {
      
      toggleFav()
      {
        this.loading = true
        
        Api.favourites.toggle( this.modelType, this.modelId )
           .then((res)=>{
            
            this.loading = false
            
            this.$emit('toggled', res.data.new_status, {
              modelType: this.modelType,
              modelId: this.modelId
            })
            
           })
      }
    }

  }
</script>
