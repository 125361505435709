<template>
  <v-speed-dial
    v-model="open"
    bottom
    right fixed
    class="hidden-print-only">
    
    <template v-slot:activator>
      <v-btn
        v-model="fab"
        color="red darken-2"
        dark
        fab
      >
        <v-icon v-if="open">
          mdi-close
        </v-icon>
        <v-icon v-else>
          {{ icon }}
        </v-icon>
      </v-btn>
    </template>
    
    <slot></slot>

  </v-speed-dial>
</template>

<script>
  export default {
    name: 'ActionButton',

    components: {},

    props: {
      icon: {
        type: String,
        default: 'mdi-apps'
      }
    },

    data: () => ({
      fab: false,
      open: false
    }),

    mounted(){},

    methods: {
      onClick()
      {
        this.$emit('click', this.book)
      }
    }

  }
</script>
