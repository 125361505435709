import _ from 'lodash'

import favourites from '../mixins/favourites'

import Utility from '@/utils'

var SearchResult = class {

	#data = {
		icon: '',
		model: '',
		text: '',
		description: '',
		value: null
	}

	constructor(init={})
	{
		// dynamic init from data based on model
		if( init.type &&
			(typeof this[`from${init.type}`] === 'function') )
			this[`from${init.type}`](init)
		else
			this.setData(init)
	}

	get description()
	{
		return this.#data.description
	}

	get icon()
	{
		return this.#data.icon
	}

	get isFav()
	{
		let isFav = false
		
		switch(this.model)
		{
			case 'quote': isFav = favourites.methods.isFavQuote({ id: this.value }); break;
		}
		
		return isFav
	}

	get model()
	{
		return this.#data.model
	}

	get owner()
	{
		// check if owner is passed from raw data
		if( this.#data.owner_id && this.#data.owner_type )
		{
			// sanitize model type name
			const ownerType = Utility.methods.getModelType(this.#data.owner_type)
			
			if(ownerType)
				return {
					id: this.#data.owner_id,
					type: ownerType.name
				}
		}
		
		return null
	}

	get text()
	{
		return this.#data.text
	}

	get value()
	{
		return this.#data.value
	}

	setData(data={})
	{
		this.#data = _.merge(this.#data, data)

		if(data.id)
			this.#data.value = data.id

		if(data.name)
			this.#data.text = data.name
		
		if(data.title)
			this.#data.text = data.title
		
		if(data.content)
			this.#data.description = data.content
		
		return this
	}

	setText(text)
	{
		this.#data.text = text
	}

	fromBook(data={})
	{
		data.icon = 'mdi-book'

		this.#data.model = 'book'
		
		this.setData(data)

		return this
	}

	fromQuote(data={})
	{
		data.icon = 'mdi-bookmark'

		this.#data.model = 'quote'

		this.#data.text = data.owner.title ?? `Book #${data.owner_id}`
		
		this.setData(data)

		return this
	}

	fromTopic(data={})
	{
		this.#data.icon = 'mdi-brain'

		this.#data.model = 'topic'

		this.setData(data)

		return this
	}

	toJSON()
	{
		return this.#data
	}
}

export default SearchResult