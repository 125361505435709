var Barcode = class {

	code = ''

	status = true

	title = ''

	constructor(code)
	{
		this.setCode(code)
	}

	setCode(code)
	{
		this.code = code
	}

	get getCode()
	{
		return this.code
	}

	get hasCode()
	{
		return !!this.code
	}

	get getTitle()
	{
		return this.title
	}

	setTitle(title)
	{
		this.title = title
	}

	toggleStatus()
	{
		this.status = !this.status
	}
}

export default Barcode